<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>{{ work_group.name }}</h1>
        </v-col>
        <v-col cols="4">
          <v-select
            label="Filter by Type"
            :items="types"
            v-model="searchType"
            hide-details
            :clearable="true"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="accent"
                class="mr-2"
                @click="$refs.workChunkForm.openForm(null, work_group)"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Add Work</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="accent"
                class="mr-2"
                @click="$refs.workGroupForm.openEditWorkGroup(work_group)"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit Work Group</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                :disabled="work_group.children.length > 0"
                color="error"
                v-on="on"
                @click="
                  archiveGroupDialog = true;
                  archiveGroupData = work_group;
                "
              >
                Delete Workgroup
              </v-btn>
            </template>
            <span>Delete Workgroup</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row align="center" justify="end" no-gutters>
        <div class="mr-2">Recalculate Time Logged Between</div>
        <v-menu
          v-model="filter.start.menu"
          transition="scale-transition"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-on="on" v-bind="attrs" label>{{
              filterStartDateFormatted
            }}</v-chip>
          </template>
          <v-date-picker
            v-model="filter.start.date"
            @input="filter.start.menu = false"
            :max="filter.end.date"
            scrollable
          ></v-date-picker>
        </v-menu>
        <div class="ml-2 mr-2">to</div>
        <v-menu
          v-model="filter.end.menu"
          transition="scale-transition"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-on="on" v-bind="attrs" label>{{
              filterEndDateFormatted
            }}</v-chip>
          </template>
          <v-date-picker
            v-model="filter.end.date"
            @input="filter.end.menu = false"
            :max="filter.max"
            scrollable
          ></v-date-picker>
        </v-menu>
        <v-btn
          color="accent"
          depressed
          text
          @click="filterTimeLogged"
          :loading="filter.loading"
          >Filter</v-btn
        >
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Work Details</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.workGroupForm.openEditWorkGroup(work_group)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Customer</th>
                  <td>
                    <router-link
                      v-if="work_group.customer"
                      :to="{
                        name: 'customer-profile-basic',
                        params: { customerId: work_group.customer.id },
                      }"
                      >{{ work_group.customer.name }}</router-link
                    >

                    <div
                      v-if="work_group.is_internal == 1"
                      style="color: #cccccc"
                    >
                      VITAL HIKE
                    </div>
                  </td>
                </tr>

                <tr>
                  <th>Description</th>
                  <td>{{ work_group.description }}</td>
                </tr>

                <tr>
                  <th>Parent Work Group</th>
                  <td>
                    <div v-if="work_group.parent">
                      {{ work_group.parent.name }}
                    </div>
                  </td>
                </tr>

                <tr>
                  <th>Type</th>
                  <td>{{ work_group.type }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card outlined class="mb-6">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Time Logged</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-data-table
              :headers="tableHeaders"
              :items="work_chunks"
              :loading="filter.loading"
              no-data-text="No work chunks found"
            >
              <template v-slot:item.name="{ item }">
                <router-link
                  :to="{
                    name: 'module-workload-individual',
                    params: { chunkId: item.id },
                  }"
                  >{{ item.name }}</router-link
                >
              </template>
            </v-data-table>

            <div>Total {{ work_group.total_time_logged.text }}</div>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="archiveGroupDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">Delete Group</v-card-title>
          <v-card-text>
            Are you sure you want to delete
            {{ archiveGroupData ? archiveGroupData.name : "" }}?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="archiveGroupDialog = false"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="archiveGroupLoading"
              @click="archiveGroup(archiveGroupData.id)"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <log-time-dialog ref="logTimeDialog" :editingWorkChunk="true" />
    <WorkChunkForm
      ref="workChunkForm"
      :groups="groups"
      :customers="customers"
      :teamMembers="teamMembers"
    />
    <WorkGroupForm
      ref="workGroupForm"
      :groups="groups"
      :customers="customers"
      :teamMembers="teamMembers"
    />
  </div>
</template>

<script>
import WorkChunkForm from "/src/modules/workload/views/chunks/components/WorkChunkForm";
import WorkGroupForm from "./components/WorkGroupForm";
import LogTimeDialog from "/src/modules/tasking/views/components/LogTimeDialog.vue";
export default {
  components: {
    WorkChunkForm,
    WorkGroupForm,
    LogTimeDialog,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Workload",
          disabled: false,
          exact: true,
          to: {
            name: "module-workload-all",
            params: { id: this.$route.params.id },
          },
        },
        {
          text: "Work Groups",
          disabled: false,
          exact: true,
          to: {
            name: "module-workgroup",
            params: { id: this.$route.params.id },
          },
        },
      ],
      tableHeaders: [
        { text: "Title", value: "name" },
        { text: "Time Logged", value: "total_time_logged.text" },
        { text: "Actions", value: "actions", align: "right" },
      ],
      searchType: null,
      archiveGroupDialog: false,
      archiveGroupLoading: false,
      archiveGroupData: null,
      types: ["Contract", "Project", "Ad-hoc", "Internal"],
      filter: {
        max: new Date().toISOString().slice(0, 10),
        start: {
          menu: false,
          date: null,
        },
        end: {
          menu: false,
          date: null,
        },
        loading: false,
      },
    };
  },

  computed: {
    work_group() {
      let group = this.$store.state.workload["work_group"];
      return group;
    },

    work_chunks() {
      let work_chunks = this.work_group.work_chunks;

      if (this.searchType !== null) {
        work_chunks = work_chunks.filter((c) => {
          const type = c.type;
          return type.includes(this.searchType);
        });
      }
      return work_chunks;
    },

    groups() {
      let groups = this.$store.state.workload["groups"];

      return groups;
    },
    customers() {
      return this.$store.state.customers["customers"];
    },
    teamMembers() {
      return this.$store.state.team["teamMembers"];
    },
    filterStartDateFormatted() {
      return this.formatDate(this.filter.start.date) ?? "DD/MM/YYYY";
    },
    filterEndDate() {
      return this.filter.end.date;
    },
    filterEndDateFormatted() {
      return this.formatDate(this.filter.end.date) ?? "DD/MM/YYYY";
    },
  },

  watch: {
    filterEndDate: function (date) {
      if (date < this.filter.start.date) {
        this.filter.start.date = date;
      }
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.work_group.name,
      disabled: true,
    });
  },

  methods: {
    archiveGroup: function (groupId) {
      const appId = this.$route.params.id;
      this.archiveGroupLoading = true;

      this.$store
        .dispatch("workload/archiveWorkGroup", {
          appId,
          groupId,
        })
        .then(() => {
          this.archiveGroupData = null;
          this.archiveGroupLoading = false;
          this.archiveGroupDialog = false;

          this.$router.push({
            name: "module-workgroup",
          });
        });
    },

    filterTimeLogged: function () {
      if (this.filter.start.date === null || this.filter.start.end === null) {
        return;
      }

      this.filter.loading = true;

      this.$store
        .dispatch("workload/filterWorkGroupTimeLogged", {
          appId: this.$route.params.id,
          groupId: this.$route.params.groupId,
          start: this.filter.start.date,
          end: this.filter.end.date,
        })
        .then(() => {
          this.filter.loading = false;
        })
        .catch(() => {
          this.filter.loading = false;
        });
    },
  },
};
</script>
